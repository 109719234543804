import { useState, useRef,useEffect  } from "react";
import { useNavigate } from 'react-router-dom';
import React from "react";
import { Container, Row, Col,Form,Button, Alert} from "react-bootstrap";
import userImage1 from '../../../assets/img/user1.jpg';
import userImage2 from '../../../assets/img/user2.jpg';
import userImage3 from '../../../assets/img/user3.jpg';
import ResponsePanelGPT from "./responsePanelGPT/ResponsePanelGPT";
import SidebarButtons from "../sidebar/sidebarButtons/SidebarButtons";
import'./ChatBody.css';
import adapter from '../../../../adapter';
import refreshAccessToken from "../../../refreshAccessToken";
import { toast } from "react-toastify";


const ChatBody = ({ sidebarSelectedElemId }) => {

  
  const userData = JSON.parse(localStorage.getItem('userData'));
  const accessToken = localStorage.getItem('accessToken');
  const [messagesFromGPT, setmessagesFromGPT] = useState([]);
  const [messages, setMessagess] = useState([]);
  const [startIndex, setStartIndex] = useState(0);
  const messagesPerPage = 20;
  
  const navigate = useNavigate();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [indexOfLastMess, setIndexOfLastMess] = useState(null);

  const filteredMessages = messages.filter((msg) => {
    return msg.reply_id !== null && msg.is_bot === false && 
           messages.find((visibleMsg) => visibleMsg.message_id === msg.reply_id)?.is_bot === true;
  });
  
  const replyIds = filteredMessages.map((msg) => msg.message_id);
  const replysLength = replyIds.length;
  const lastMessages = messages[messages.length - 1]


  const [activeButton, setActiveButton] = useState("");
  const handleButtonClick = (buttonType) => {
    if (buttonType === "previous") {
      if (buttonType === "previous") {
        if (currentIndex > 0) {
          setCurrentIndex(currentIndex - 1);
          setActiveButton("previous");
          if(indexOfLastMess){
             setIndexOfLastMess(null);
          }
        }
      } 
    } 
    else if (buttonType === "next") {
      if (currentIndex < replysLength - 1) {
        setCurrentIndex(currentIndex + 1);
        setActiveButton("next");
        if(indexOfLastMess){
           setIndexOfLastMess(null);
        }
       
      }
    } 
    else if (buttonType === "new") {
      const confirmed = window.confirm("Вы уверены, что хотите загрузить новые сообщения?");
      if (confirmed) {
            fetchNewMessages();
          }
      setActiveButton("new");
    }
    else if (buttonType === "last") {
      setActiveButton("last")
      setIndexOfLastMess(lastMessages.message_id);
    }
  };

  const [buttonPrew, setButtonPrew] = useState(false);
  const [buttonNext, setButtonNext] = useState(false);
  const [buttonLast, setButtonLast] = useState(false);

  useEffect(() => {
    if (messages.length > 0) {
      if (activeButton !== "last") {
        const elementId = replyIds[currentIndex];
        
        const targetElement = document.getElementById(`message-${elementId}`);
        
        if (targetElement) {
          if (indexOfLastMess) {
            setIndexOfLastMess(null);
          }
          targetElement.scrollIntoView();
        }
      }else {
        const targetElement = document.getElementById(`message-${indexOfLastMess}`);
        if (targetElement) {
          targetElement.scrollIntoView();
          setCurrentIndex(replyIds.length - 1);
        }
      }
    }
    if (currentIndex === 0 && indexOfLastMess === null) {
      setButtonPrew(true);
    }else{
      setButtonPrew(false);
    }
    if (currentIndex === replyIds.length - 1 && indexOfLastMess === null) {
      setButtonNext(true);
    }
    else if(indexOfLastMess !== null){
      setButtonNext(true);
    }
    else{
      setButtonNext(false);
    }
    if (indexOfLastMess !== null) {
      setButtonLast(true);
     
    } else {
      setButtonLast(false);
      
    }
    
 
  }, [currentIndex, indexOfLastMess]);

  useEffect(() => {
    if (messages.length > 0) {
      const elementId = replyIds[0];
      const targetElement = document.getElementById(`message-${elementId}`)
      if (targetElement) {
        targetElement.scrollIntoView();
      }
    }
  
  }, [messages.length === 0]);

  const getMessages = async () => {
    if (sidebarSelectedElemId !== null) {
        try {
          
          const response = await adapter.get('/messages', {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
            params: { selectedGroup: sidebarSelectedElemId }
          });
          if (response.data.result === 'success') {
            setMessagess(response.data.array);
          } else if (response.data.result === 'error' && response.data.message === 'Token is expired') {
            const refreshedToken = await refreshAccessToken(accessToken);
            if (refreshedToken === 'logout') {
              navigate('/', { replace: true });
            } else {
              const response = await adapter.get('/messages', {
                headers: {
                  Authorization: `Bearer ${refreshedToken}`,
                },
                params: { selectedGroup: sidebarSelectedElemId }
              });
              if (response.data.result === 'success') {
                setMessagess(response.data.array);
              } else {
                console.log(response.data.message);
              }
            }
          }else if (response.data.result === 'error' && response.data.message === 'No messages found'){
            setMessagess([]);
          } else {
            console.log(response.data.message);
          }
        } catch (error) {
          console.log(error)
        }
        
    }
  };

  const lastMessageRef = useRef(null);


  const fetchNewMessages = async () => {
    if (sidebarSelectedElemId !== null) {
      try {
        const response = await adapter.get('/recent_messages', {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          params: { 
            selectedGroup: sidebarSelectedElemId,
            newMessagesCount: newMessages,
            id:userData.id 
          }
        });
        if (response.data.result === 'success') {
          const allMessages = response.data.result;
          const recentMessagesCount = response.data.recentMessages;
          const targetIndex = allMessages.length - recentMessagesCount;
          const element = allMessages[targetIndex];
          const elementId = element.message_id;
          setStartIndex(elementId);
          setMessagess(allMessages);
  
          if (targetIndex >= 0) {
            setTimeout(() => {
              const targetElement = document.getElementById(`message-${elementId}`);
              const existingIndicator = document.querySelector('.new-messages-indicator');
  
              if (existingIndicator) {
                existingIndicator.remove();
              }
  
              if (targetElement) {
                const inner = document.createElement('div');
                inner.innerText = 'Новые сообщения';
                inner.className = 'new-messages-indicator';
                inner.style.cssText = 'border-top: 1px solid; text-align: center; font-size: 12px;';
                
                targetElement.parentNode.insertBefore(inner, targetElement);
                targetElement.scrollIntoView();
              }
            }, 100);
          }
        }else if (response.data.result === 'error' && response.data.message === 'Token is expired') {
          const refreshedToken = await refreshAccessToken(accessToken);
          if (refreshedToken === 'logout') {
            navigate('/', { replace: true });
          }else {
            const response = await adapter.get('/recent_messages', {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
              params: { 
                selectedGroup: sidebarSelectedElemId,
                newMessagesCount: newMessages,
                id:userData.id 
              }
            });
            if (response.data.result === 'success') {
              const allMessages = response.data.result;
              const recentMessagesCount = response.data.recentMessages;
              const targetIndex = allMessages.length - recentMessagesCount;
              const element = allMessages[targetIndex];
              const elementId = element.message_id;
              setStartIndex(elementId);
              setMessagess(allMessages);
      
              if (targetIndex >= 0) {
                setTimeout(() => {
                  const targetElement = document.getElementById(`message-${elementId}`);
                  const existingIndicator = document.querySelector('.new-messages-indicator');
      
                  if (existingIndicator) {
                    existingIndicator.remove();
                  }
      
                  if (targetElement) {
                    const inner = document.createElement('div');
                    inner.innerText = 'Новые сообщения';
                    inner.className = 'new-messages-indicator';
                    inner.style.cssText = 'border-top: 1px solid; text-align: center; font-size: 12px;';
                    
                    targetElement.parentNode.insertBefore(inner, targetElement);
                    targetElement.scrollIntoView();
                  }
                }, 100);
              }
            } else {
              console.log(response.data.message);
            }
          }
        }else if (response.data.result === 'error' && response.data.message === 'No messages found'){
          console.log('No messages found');
        }else {
          console.log(response.data.message);
        }
      } catch (error) {
        console.log(error)
      }
    }
  };

  const [newMessages, setNewMessages] = useState([]);

  const checkNewMessages = async () => {
    if (sidebarSelectedElemId !== null) {
      try {
        const response = await adapter.get('/check_new_messages', {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          params: { selectedGroup: sidebarSelectedElemId, id: userData.id }
        });
        if (response.data.result === 'success') {
          setNewMessages(response.data.array);
        }else if (response.data.result === 'error' && response.data.message === 'Token is expired') {
          const refreshedToken = await refreshAccessToken(accessToken);
          if (refreshedToken === 'logout') {
            navigate('/', { replace: true });
          }else {
            const response = await adapter.get('/check_new_messages', {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
              params: { selectedGroup: sidebarSelectedElemId, id: userData.id }
            });
            if (response.data.result === 'success') {
              setNewMessages(response.data.array);
            }else {
              console.log(response.data.message);
            }
          }
        }else if (response.data.result === 'error' && response.data.message === 'No messages found'){
          setNewMessages([]);
        }else {
          console.log(response.data.message);
        }
      } catch (error) {
        console.log(error)
      }
    }
  };
  
  useEffect(() => {
    getMessages();
    const intervalId = setTimeout(() => {
      checkNewMessages();
      setInterval(() => {
        checkNewMessages();
      }, 30000);
    }, 30000);
  
    return () => {
      clearTimeout(intervalId);
    };
  }, [sidebarSelectedElemId]);



  const chatBodyContentStyle = {
    position:'relative',
    height: 'calc(93vh - 160px)', 
    overflowY: 'scroll', 
    backgroundColor:'#36393F',
    paddingTop:'50px',
    paddingBottom:'15px',
    paddingLeft:'25px'
    
    
  };
  
  const chatInputStyle = {
    border: 'none',
    borderRadius: '20px',
    padding: '10px 20px'
  };

  const chatButtonStyle = {
    backgroundColor: '#4F545C',
    border: 'none',
    borderRadius: '20px',
    color: '#FFFFFF',
    padding: '10px 25px',
    width: '100%'
  };
  const sendMessageStyle = {
    display:'flex',
    position: 'absolute',
    bottom: '-105px',
    left:'0',
    right:'0',
    backgroundColor: '#40444B',
    border: 'none',
    borderRadius: '10px',
    marginBottom: '10px',
    padding: '10px'
  }
  
  const replyStyle = {
    boxShadow: '0px 0px 10px 7px #ed424521',
    padding:'10px 25px'
  }
  const test = {
    backgroundColor: '#2f3136',
    padding: '25px 25px',
    borderRadius: '25px 25px 25px 0px',
  }


  const messageInput = useRef();

  const [selectedValue, setSelectedValue] = useState("");
  const [inputValue, setInputValue] = useState("");

  const handleMessageSend = async () => {
    const replyMsg = document.getElementById("selectiveReply");
    if (replyMsg) {
      const isBlockEmpty = replyMsg.innerHTML.trim() === '';
      if (isBlockEmpty) {
        if (selectedValue != '') {
          const message = {
            selectedValue,
            sidebarSelectedElemId,
            inputValue: inputValue, 
            id: userData.id
          };
          try {
            const response = await adapter.post('/send_message', message, {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            });
            if (response.data.result === 'success') {
              if(sidebarSelectedElemId){
                toast.success('Сообщение отправлено');
                setSelectedValue(''); 
                setInputValue('');
              }else{
                toast.error('Выберите чат!');
              }
            }else if (response.data.result === 'error' && response.data.message === 'Token is expired') {
              const refreshedToken = await refreshAccessToken(accessToken);
              if (refreshedToken === 'logout') {
                navigate('/', { replace: true });
              }else {
                const response = await adapter.post('/send_message', message, {
                  headers: {
                    Authorization: `Bearer ${accessToken}`,
                  },
                });
                if (response.data.result === 'success') {
                  if(sidebarSelectedElemId){
                    alert('Сообщение отправлено');
                    setSelectedValue(''); 
                    setInputValue('');
                  }else{
                    alert('Выберите чат!');
                  }
                }
              }
            }else {
              console.log(response.data.message);
              toast.error('Ошибка отправки!  ' + response.data.message);
            }
          } catch (error) {
            console.log(error)
          }
          
        } else {
          toast.error('Пожалуйста выберите бота!');
        }
      } else {
        // Ответ на любое сообщение
        if (selectedValue != '') {

          const selectiveReplyElement = document.getElementById('selectiveReply');
          const messageID = selectiveReplyElement.querySelector('.row').getAttribute('message-id');
          const messageText = selectiveReplyElement.querySelector('.message-text').innerText;
          const message = {
            selectedValue,
            sidebarSelectedElemId,
            inputValue: inputValue,
            messageID: messageID,
            messageText: messageText,
            id: userData.id
          };
          try {
            const response = await adapter.post('/send_selective_reply', message, {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            });
            if (response.data.result === 'success') {
              if(sidebarSelectedElemId){
                toast.success('Сообщение отправлено');
                setSelectedValue(''); 
                setInputValue('');
                const selectiveReply =  document.getElementById('selectiveReply');
                if (selectiveReply) {
                  selectiveReply.innerHTML = '';
                }
              }else{
                toast.error('Выберите чат!');
              }
            }else if (response.data.result === 'error' && response.data.message === 'Token is expired') {
              const refreshedToken = await refreshAccessToken(accessToken);
              if (refreshedToken === 'logout') {
                navigate('/', { replace: true });
              }else {
                const response = await adapter.post('/send_selective_reply', message, {
                  headers: {
                    Authorization: `Bearer ${accessToken}`,
                  },
                });
                if (response.data.result === 'success') {
                  toast.success('Сообщение отправлено');
                  setSelectedValue(''); 
                  setInputValue('');
                  const selectiveReply =  document.getElementById('selectiveReply');
                  if (selectiveReply) {
                    selectiveReply.innerHTML = '';
                  }
                }
              }
            }else {
              console.log(response.data.message);
              toast.error('Ошибка отправки!  ' + response.data.message);
            }
          } catch (error) {
            console.log(error)
          }
        } else {
          alert('пожалуйста выберите бота');
        }
      }
    }

    
  };

  
  useEffect(() => {
    if (messages.length <= startIndex) {
      setStartIndex(Math.max(0, messages.length - messagesPerPage));
    }
  }, [messages, startIndex]);
  


  const replyRef = useRef(null);

  const [selectedMessage, setSelectedMessage] = useState(false);
  const [replyContent, setReplyContent] = useState('');

  function handleDoubleClick(event) {
    setSelectedMessage(true);
    const messageID = event.currentTarget.getAttribute('id').replace('message-', '');
    const messageSenderName = event.currentTarget.querySelector('.fw-bold').innerText;
    const imageElement = event.currentTarget.querySelector('img');
    const src = imageElement.src;
    const messageText = event.currentTarget.querySelector('.message-text').innerText;

    const selectiveReplyMsg = `
      <small class="mb-2 me-2 d-block"><em>Ответить на:</em></small>
      <div class="row" message-id="${messageID}">
        <div class="col-md-1 me-3">
          <img
            src="${src}"
            alt="${messageSenderName}"
            style="width: 50px; height: 50px; border-radius: 50%;"
          />
        </div>
        <div class="col-md-10" style="${replyStyle}">
          <div>
            <div class="d-flex align-items-center">
              <div class="fw-bold">${messageSenderName}</div>
              <p class="m-0 ms-2" style="н: 10px;">
                <!-- {msg.timestamp} -->
              </p>
            </div>
            <div class="message-text">${messageText}</div>
          </div>
        </div>
      </div>
    `;
    
    setReplyContent(selectiveReplyMsg);
  }
  const [dbBots, setDbBots] = useState([]);

  const handleGetBots = async () => {
    try {
      const response = await adapter.get('/get_bots', {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        params: { userId: userData.id }
      });
      if (response.data.result === 'success') {
        setDbBots(response.data.array);
        if(response.data.array.length > 0){
          setSelectedValue(response.data.array[0].username);
        }
      } else if (response.data.result === 'error' && response.data.message === 'Token is expired') {
        const refreshedToken = await refreshAccessToken(accessToken);
        if (refreshedToken === 'logout') {
          navigate('/', { replace: true });
        } else {
          const response = await adapter.get('/get_bots', {
            headers: {
              Authorization: `Bearer ${refreshedToken}`,
            },
            params: { userId: userData.id }
          });
          if (response.data.result === 'success') {
            setDbBots(response.data.array);
            if(response.data.array.length > 0){
              setSelectedValue(response.data.array[0].username);
            }
          } else {
            console.log(response.data.message);
          }
        }
      }else if (response.data.result === 'error' && response.data.message === 'No bots found'){
        setDbBots([]);
      } else {
        console.log(response.data.message);
      }
    } catch (error) {
      console.log(error)
    }
  };

  useEffect(() => {
    handleGetBots();
  }, []);

  const [gptAnswer, setGptAnswer,] = useState([]);
  const answerQuestion = async (previousMessages, question) => {
    // setGptAnswer('lol kek  cheburek');
    try {
      const response = await adapter.post(
        '/answer_question',
        {
          question: question,
          password:"QhjkhJKHDJlsd82JHKL",
          previousMessages: previousMessages,
          chatID: sidebarSelectedElemId,
          lang: 'ru'
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
  
      if (response.data) {
        setGptAnswer(response.data.answers);
       

      } else {
        console.error('Error in response:', response.data);
      }
    } catch (error) {
      console.error('Error during request:', error);
    }
  };

  const [replyAnswers, setReplyAnswers] = useState([]);

  const handleGetAnswer = (id, reaskQuestion) => {
    
    const targetMessage = messages.find(item => item.message_id === id);
   
    if (!targetMessage || !targetMessage.reply_id) {
        return null;
    }
    const targetIndex = messages.findIndex(item => item.message_id === id);
    const previousMessages = [];

    for (let i = targetIndex - 1; i >= 0 && previousMessages.length < 3; i--) {
        previousMessages.unshift(messages[i].message);
    }

   
    answerQuestion(previousMessages, reaskQuestion? reaskQuestion : targetMessage.message);
    setReplyAnswers(prevState =>{
      const existingIndex = prevState.findIndex(answer => answer.repliedId === id);

      if (existingIndex !== -1) {
        const updatedState = [...prevState];
        updatedState[existingIndex] = { repliedId: id, message: targetMessage };
        return updatedState;
      } else {
        return [
          ...prevState,
          { repliedId: id, message: targetMessage.message }
        ];
      }
    });

  }

  return (
    <Container fluid className="position-relative px-0" >
      <div  style={chatBodyContentStyle}>
        <div className="message-list">
        {messages.length > 0 ? (
          messages.map((msg, index) => {
            const isBotResponseToBot =
              msg.is_bot &&
              msg.reply_id &&
              messages.find(
                (visibleMsg) => visibleMsg.message_id === msg.reply_id && visibleMsg.is_bot
              );
          
            const isBotResponseToUser =
              msg.is_bot &&
              msg.reply_id &&
              !messages.find(
                (visibleMsg) => visibleMsg.message_id === msg.reply_id && visibleMsg.is_bot
              );
          
            const isUserToBot = 
              !msg.is_bot && msg.reply_id !== null && 
              messages.some(m => m.message_id === msg.reply_id && m.is_bot);
          
            const messageClasses = ["message", "p-4", "my-3"];
          
            let reply;
          
            if (isUserToBot) {
              reply = messages.filter((m) => m.message_id === msg.reply_id);
              messageClasses.push("user-to-bot-question");
            } else if (isBotResponseToBot) {
              messageClasses.push("bot-to-bot-question");
            } else if (isBotResponseToUser) {
              messageClasses.push("bot-to-user-question");
            }
          
            return (
              <div
                key={index}
                id={`message-${msg.message_id}`}
                className={messageClasses.join(" ") }
                ref={lastMessageRef}
                onDoubleClick={handleDoubleClick}
              >
                <Row>
                  <Col md={1} className="me-3">
                    <img
                      src={
                        msg.sender_name === "Irene"
                          ? userImage1
                          : msg.sender_name === "Maya"
                          ? userImage2
                          : userImage3
                      }
                      alt={msg.sender_name}
                      style={{ width: "50px", height: "50px", borderRadius: "50%" }}
                    />
                  </Col>
                  <Col md={10} style={reply ? replyStyle : test}>
                    <div ref={replyRef}>
                      {msg.reply_id && (
                        <div className="reply">
                          <p>
                            <strong>
                              @
                              {
                                messages.find(
                                  (visibleMsg) => visibleMsg.message_id === msg.reply_id
                                )?.sender_name
                              }:
                            </strong>{" "}
                            {
                              messages.find(
                                (visibleMsg) => visibleMsg.message_id === msg.reply_id
                              )?.message
                            }
                          </p>
                        </div>
                      )}
                      <div className="d-flex align-items-center">
                        <div className="fw-bold">{msg.sender_name}</div>
                      </div>
                      <div className="message-text">
                        {msg.message !== "" ? (
                          msg.message
                        ) : (
                          <strong>====- Вместо сообщения отправлен файл -====</strong>
                        )}
                      </div>
                      {reply && (
                        <ResponsePanelGPT
                          replyId={msg.message_id}
                          getAnswer={handleGetAnswer}
                          gptAnswer={gptAnswer}
                          sidebarSelectedElemId={sidebarSelectedElemId}
                        />
                      )}
                    </div>
                  </Col>
                </Row>
              </div>
            );
          })
          
        ) : (
          <small>
            <em>Выберите чат из списка слева.</em>
          </small>
        )}
           


        </div>



{/* 
          {startIndex + messagesPerPage < messages.length && (
            <div className="skip-message-btn-next">
              <button className="skip-message-btn" onClick={handleNextClick}>
                Перейти к следующим
              </button>
            </div>
          )} */}
      </div>
      <Row style={sendMessageStyle}>
        <Col>
          <Container fluid >
          {selectedMessage && (
            <Row className="d-flex justify-content-between align-items-center py-2 pb-4">
              <Col md={1}></Col>
              <Col md={10} id="selectiveReply">
                <div dangerouslySetInnerHTML={{ __html: replyContent }} />
              </Col>
              <Col md={1}></Col>
            </Row>
          )}
              <Row className="d-flex justify-content-between align-items-center py-2 pb-4">
                <Col md={3}>
                  {dbBots.length > 0 ? (
                    <Form.Select value={selectedValue} onChange={(e) => setSelectedValue(e.target.value)}>
                      {dbBots
                        .filter((bot) => !bot.nospeak) 
                        .map((bot) => (
                          <option key={bot.bot_id} value={bot.label || bot.username}>{bot.username}</option>
                        ))}
                    </Form.Select>
                  ) : (
                    <small>Нужно добавить ботов в этот чат!</small>
                  )}
                </Col>
                <Col md={7}>
                  <Form.Control type="text" placeholder="Введите ваше сообщение здесь" ref={messageInput} style={chatInputStyle} value={inputValue} onChange={(e) => setInputValue(e.target.value)}/>
                </Col >
                <Col md={2}>
                  <Button  onClick={handleMessageSend} style={chatButtonStyle}>Отправить</Button>
                </Col >
              </Row>
          </Container>
          
        </Col>
        <SidebarButtons handleButtonClick={handleButtonClick} setCurrentIndex={currentIndex} setIndexOfLastMess={indexOfLastMess} setButtonPrew={buttonPrew} setButtonNext={buttonNext}  setButtonLast={buttonLast} setNewMessages={newMessages}/>
      </Row>
    </Container>
  );
};

export default ChatBody;